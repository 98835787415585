// @font-face {
// 	font-family: 'GaijiBengoshi';
// 	src: url("../font/JFBA_font_lawyers.woff") format("woff"); }

// @font-face {
// 	font-family: 'Gaijihoujin';
// 	src: url("../font/JFBA_font_corporations.woff") format("woff"); }

// .fontExCorp {
// 	font-family:  "GaijiBengoshi" ,$ff_ja;
// 	webkit-font-family:  "GaijiBengoshi", $ff_ja;
// 	-moz-osx-font-family:  "GaijiBengoshi" , $ff_ja;
// }

// .fontExLaw {
// 	font-family:  "Gaijihoujin" ,$ff_ja;
// 	webkit-font-family:  "Gaijihoujin", $ff_ja;
// 	-moz-osx-font-family:  "Gaijihoujin" , $ff_ja;
// }

*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body{
	overflow-x: hidden;
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 400;
	background: #fff;
	line-height: 1.6;
	//	text-align: justify;
	//	text-justify: inter-ideograph;

	@if $type==sp{
		min-width: 320px;
		font-size: 16px;
	}

	@if $type==pc{
		font-size: 16px;
	}
}

h1,
h2,
h3,
h4,
h5,
h6{
	line-height: 1;
}

dl{
	margin-bottom: 0;
}

:root ol, :root ul{
	//	margin: 0;
	//	padding: 0;
}

.pbFooterArea,
:root .pbAreaWrapper1:after{
	clear: none;
	overflow: visible;
}

.pbHeaderArea{
	overflow: visible;
}



// parts ----------------------------------

a{

	@include linkTxt;}

img{
	@if $type==sp{}

	@if $type==pc{
		max-width: 100%;
	}
}

.align_center{
	text-align: center;
}

.bold{
	font-weight: bold;
}

ul{
	//	list-style: none;
}

.inner{
	@if $type==sp{
		margin: 0 15px;
	}

	@if $type==pc{
		max-width: 1220px;
		width: 95%;
		margin: 0 auto;
		margin: 0 auto;
		position: relative;
	}
}

.text_link{
	@if $type==sp{}

	@if $type==pc{
		padding-left: 15px;
		display: inline-block;
		position: relative;

		&:hover{
			color: $c_pink;
		}
	}

	&::before{
		@if $type==sp{}

		@if $type==pc{
			content: "";
			position: absolute;
			top: 50%;
			left: 0;
			transform: rotate(45deg);
			width: 6px;
			height: 6px;
			border-top: 2px solid $c_pink;
			border-right: 2px solid $c_pink;
			margin-top: -3px;
		}
	}
}

.bt_small{
	@if $type==sp{
		display: inline-block;
		padding: 5px 35px 5px 30px;
		position: relative;
		color: #fff;
		font-size: 16px;
		border-radius: 4px;
		width: 70%;
		text-align: center;
	}

	@if $type==pc{
		display: inline-block;
		padding: 5px 35px 5px 30px;
		position: relative;
		color: #fff;
		font-size: 16px;
		border-radius: 4px;
		transition: all .4s;

		&:hover{
			opacity: .8;
		}
	}

	&::before{
		@if $type==sp{
			content: "";
			width: 6px;
			height: 6px;
			border-top: 2px solid #fff;
			border-right: 2px solid #fff;
			transform: rotate(45deg);
			position: absolute;
			top: 50%;
			left: 10px;
			margin-top: -3px;
		}

		@if $type==pc{
			content: "";
			width: 6px;
			height: 6px;
			border-top: 2px solid #fff;
			border-right: 2px solid #fff;
			transform: rotate(45deg);
			position: absolute;
			top: 50%;
			left: 10px;
			margin-top: -3px;
		}
	}
}

.bg_pink{
	background: $c_pink;
}

.bg_blue{
	background: $c_blue;
}

input{
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	box-shadow: none;
}

input:focus{
	outline: 0;
	border-color: orange;
}

.forPC{
	@if $type==sp{
		display: none;
	}

	@if $type==pc{}
}

.forSP{
	@if $type==sp{}

	@if $type==pc{
		display: none;
	}
}

#page{
	overflow: hidden;
}




// header ----------------------------------


header{
	@media screen and (max-width:979px){
		position: relative;
		width: 100%;

		& > .inner{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			margin: 0 auto;
			width: 95%;
			padding: 2.5vw 0;

			#site_logo{
				flex: 1;
				display: flex;

				a{
					display: block;
					img{
						width: 100%;
					}
				}

				#secTitle{
					font-size: 12px;
					border: solid 1px #cfcfcf;
					padding: 5px 7px;
					margin-left: 5px;
					color: #24504f;
					line-height: 1;
					white-space: nowrap;
				}
			}

			#headerRight{
				display: none;
			}

			#menuBtn{
				background: url(../images/common/bt_menu.png) no-repeat;
				background-size: cover;
				width: 47px;
				height: 47px;
				color: $green;
				display: flex;
				align-items: flex-end;
				justify-content: center;
				font-size: 10px;
				padding-bottom: 5px;
				margin-left: 10px;
			}

			#headerSearchWin{
				width: 100%;
				margin-top: 2.5vw;

				form{

					#searchWinInner{
						display: flex;
						border: 1px solid $blue;

						input[type = text]{
							flex: 1;
							width: calc(100% - 50px);
							height: 48px;
							padding: 0 5px 0 10px;
						}

						label{
							width: 48px;
							height: 48px;
							border-left: 1px solid $blue;
							background-image: url(../images/common/sp-search-btn.png);
							background-repeat: no-repeat;
							background-position: 50% 50%;
							background-size: auto 80%;

							input[type = submit]{
								display: none;
							}
						}

						#searchCloseBtn{
							display: none;
						}
					}
				}
			}
		}
	}

	@media print, screen and (min-width:980px){

		.inner{
			display: flex;
			justify-content: space-between;
			max-width: 1220px;
			width: 95%;
			position: relative;
			height: 140px;

			#site_logo{
				display: flex;
				height: 140px;
				align-items: center;

				#secTitle{
					font-size: 18px;
					border: solid 1px #cfcfcf;
					padding: 10px 10px;
					margin-left: 10px;
					color: $green;
					line-height: 1;
				}
			}

			#headerRight{
				display: flex;
				align-items: center;
				margin: 0 0 0 auto;

				#user{
					text-align: right;

					#userName{
						max-width: 313px;
						margin-left: auto;
						background-color: #F7F6F4;
						height: 40px;
						border-radius: 20px;
						display: flex;
						justify-content: center;
						align-items: center;
						padding: 0 20px;
						font-size: 16px;

						span{
							font-size: 16px;
							padding: 1em;
							font-weight: 700;
							color: $green;
						}
					}

					a#profile{
						font-size: 14px;
						color: $green;
						text-decoration: none;

						&:hover{
							text-decoration: underline;
						}
					}

					a#Mailmagazine {
						font-size: 14px;
						color: $green;
						text-decoration: none;

						&:hover{
							text-decoration: underline;
						}
					}

					a#logOut{
						font-size: 14px;
						color: $green;
						text-decoration: none;

						&:hover{
							text-decoration: underline;
						}
					}

					span{
						font-size: 14px;
						color: $green;
					}
				}

				#searchBtn{
					margin-left: 30px;
					cursor: pointer;
				}
			}

			#menuBtn{
				display: none;
			}
		}

		#headerSearchWin{
			position: absolute;
			height: 100%;
			width: 670px;
			top: 0;
			right: -100%;
			background-color: #fff;
			display: flex;
			align-items: center;
			transition: all .3s;

			&.isShow{
				right: 0;
			}

			form{
				display: block;
				width: 100%;
			}

			#searchWinInner{
				border: 2px solid $blue;
				display: flex;
				width: 100%;
				height: 80px;

				input[type = text]{
					flex: 1;
					font-size: 16px;
					padding: 0 1em;

					&:focus{
						background-color: #f6f4ff;
					}
				}

				label{
					width: 80px;
					background-image: url(../images/common/ico_search.png);
					background-repeat: no-repeat;
					background-position: 50% 50%;
					cursor: pointer;

					&:hover{
						background-color: rgba($blue, .3);
					}

					input{
						display: none;
					}
				}

				#searchCloseBtn{
					width: 68px;
					border-left: 2px solid $blue;
					position: relative;
					text-align: center;
					color: $blue;
					padding-top: 5px;
					cursor: pointer;

					&:hover{
						background-color: rgba($blue, .3);
					}

					&:before{
						content: "";
						display: block;
						width: 2px;
						height: 30px;
						transform: rotate(45deg);
						background-color: $blue;
						position: absolute;
						bottom: 20px;
						left: 33px;
					}

					&:after{
						content: "";
						display: block;
						width: 2px;
						height: 30px;
						transform: rotate(-45deg);
						background-color: $blue;
						position: absolute;
						bottom: 20px;
						left: 33px;
					}
				}
			}
		}

		li.current{
			background: #cd634c;

			&:after{
				content: "";
				position: absolute;
				right: 0;
				/* bottom: -20px; */
				left: 0;
				width: 0px;
				height: 0px;
				margin: auto;
				border-style: solid;
				border-color: #cd634c transparent transparent transparent;
				border-width: 10px 10px 0 10px;
			}
		}

	}
}

// nav ----------------------------------
nav{
	@media screen and (max-width:979px){
		background: $mint;
		padding: 5px 0;
		position: fixed;
		top: -100vh;
		left: 0;
		width: 100%;
		z-index: 20;
		height: 100vh;
		overflow: hidden;
		transition: all .3s;
		z-index: 999;

		&.isOpen{
			top: 0;
		}

		#spNavHeader{
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 95%;
			margin: 0 auto;
			height: 46px;

			#spUser{
				background-color: #fff;
				height: 40px;
				border-radius: 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0 20px;
				font-size: 16px;
				line-height: 1;

				span{
					font-size: 18px;
					padding: 0 .5em;
					color: $green;
					max-width: 8em;
					overflow: hidden;
					line-height: 1em;
					height: 1em;
					display: inline-block;
				}
			}

			#closeBtn{
				width: 46px;
				height: 46px;
				margin-left: 10px;

				img{
					width: 100%;
					height: auto;
				}
			}
		}

		#gNavWrap{
			overflow: scroll;
			height: calc(100vh - 56px);
			padding-bottom: 40px;
		}

		ul.inner{
			margin: 0;
			margin-top: 20px;
			border-top: 2px solid #fff;
			list-style: none;
			padding: 0;

			& > li{
				font-size: 16px;
				font-weight: bold;
				position: relative;
				border-bottom: 2px solid #fff;

				.dropMenu{
					display: none;
				}

				& > a, & > span{
					color: $green;
					display: block;
					padding: 10px 15px;
					position: relative;
					color: $green;

					&:after{
						content: "";
						display: block;
						width: 6px;
						height: 6px;
						border-top: 2px solid $green;
						border-right: 2px solid $green;
						transform: rotate(45deg) translateY(-50%);
						position: absolute;
						right: 15px;
						top: 50%;
					}
				}

				& > span{
					&:after{
						display: none;
					}
				}

				.child{
					background-color: #EBFFFB;
					margin: 0 1em 1em;

					ul{
						list-style: none;
						margin-top: 0;

						li{

							& + li{
								border-top: 1px solid #bef1e6;
							}

							a{
								color: $green;
								text-decoration: none;
								position: relative;
								display: block;
								padding: .5em;

								&:after{
									content: "";
									display: block;
									width: 6px;
									height: 6px;
									border-top: 2px solid $green;
									border-right: 2px solid $green;
									transform: rotate(45deg) translateY(-50%);
									position: absolute;
									right: 15px;
									top: 50%;
								}
							}
						}
					}
				}
			}
		}
	}



	@media print, screen and (min-width:980px){
		background: $mint;
		position: relative;
		z-index: 999;

		#spNavHeader{
			display: none;
		}

		#gNavWrap{
			height: 68px;

			ul.inner{
				display: flex;
				justify-content: center;
				margin: 0 auto;
				padding: 0;
				list-style: none;
				height: 68px;
				max-width: 1220px;
				width: 100%;

				& > li{
					font-size: 18px;
					position: relative;
					transition: all .3s;

					&.spMenu{
						display: none;
					}

					&.now, &:hover{
						background-color: $green;

						&:before{
							content: "";
							display: block;
							width: 0;
							height: 0;
							border-top: 10px solid $green;
							border-left: 10px solid transparent;
							border-right: 10px solid transparent;
							position: absolute;
							left: calc(50% - 10px);
							bottom: -10px;
							z-index: 11;
						}

						a, span{
							color: #fff;
						}
					}

					& > a, span{
						color: $green;
						display: flex;
						height: 68px;
						align-items: center;
						position: relative;
						z-index: 1;
						text-decoration: none;
						padding: 0 35px;
						font-weight: 700;
					}

					&.parent{
						.child{
							border: 5px solid #bef1e6;
							border-width: 0 5px 5px 5px;
							position: absolute;
							bottom: 0;
							left: 50%;
							transform: translateY(100%) translateX(-50%);
							display: none;

							ul{
								list-style: none;
								display: flex;
								padding: 0;
								margin: 0;

								li{
									background-color: #EBFFFB;
									position: relative;

									& + li{
										&:before{
											content: "";
											display: block;
											width: 1px;
											height: 1em;
											background-color: #088E98;
											position: absolute;
											left: 0;
											top: 50%;
											transform: translateY(-50%);
										}
									}

									a{
										display: block;
										line-height: 1;
										padding: 10px 15px;
										color: #088E98;
										text-decoration: none;
										white-space: nowrap;
										position: relative;
										border-bottom: 3px solid #EBFFFB;

										&:hover{
											border-color: #088E98;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// footer ----------------------------------

footer{
	@media screen and (max-width:979px){
		margin-top: 35px;
		position: relative;

		.inner{
			width: 100%;
			margin: 0;
			padding-top: 90px;

			#mente{
				width: 170px;
				height: 40px;
				background-color: #fff;
				border: 1px solid #cfcfcf;
				position: absolute;
				top: 0;
				left: 2.5vw;

				a{
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					color: #000;
					padding: 0 20px 0 10px;
				}
			}

			#backBtn{
				width: 170px;
				height: 40px;
				background-color: #fff;
				border: 1px solid #cfcfcf;
				position: absolute;
				left: 2.5vw;
				top: 45px;
				z-index: 10;

				a{
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #000;
					padding: 0 20px 0 10px;
					font-size: 14px;
					position: relative;

					&:before{
						content: "";
						display: block;
						width: 8px;
						height: 8px;
						border-bottom: 2px solid #ccc;
						border-left: 2px solid #ccc;
						transform: rotate(45deg);
						position: absolute;
						top: 15px;
						left: 15px;
					}
				}
			}

			#pageTop{
				width: 40px;
				height: 40px;
				background-color: $green;
				position: absolute;
				right: 2.5vw;
				top: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				z-index: 10;
				cursor: pointer;
				padding-top: 3px;

				&:after{
					content: "";
					display: block;
					width: 10px;
					height: 10px;
					border-left: 2px solid #fff;
					border-top: 2px solid #fff;
					transform: rotate(45deg);
				}
			}

			#footerLogo{
				background-color: $mint;
				padding: 15px 10px 10px;
				margin: 0 0 0;
				width: 100%;
				text-align: center;

				img{
					max-width: 60%;
					height: auto;
				}
			}

			#footerMenu{
				display: flex;
				margin: 0;
				justify-content: center;
				padding: 10px 5px;
				list-style: none;
				background-color: $mint;
				flex-wrap: wrap;

				li{
					margin: 0 10px;

					a{
						color: $green;
						font-size: 12px;
					}
				}
			}
		}

		p{
			background-color: $mint;
			font-size: 10px;
			padding: 10px;
			text-align: center;
			
			&.copy{
				margin: 0;
			}
		}
	}





	@media print, screen and (min-width:980px){
		width: 100%;
		background-color: $mint;
		padding: 0 0 50px;

		.inner{
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
			max-width: 1220px;
			width: 90%;
			margin: 0 auto;
			padding: 50px 0 0;
			z-index: 0;

			#mente{
				width: 170px;
				height: 40px;
				background-color: #fff;
				border: 1px solid #cfcfcf;
				position: absolute;
				left: 0;
				top: -60px;
				z-index: 10;

				a{
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					color: #000;
					padding: 0 20px 0 10px;
					font-size: 14px;
				}
			}

			#backBtn{
				width: 170px;
				height: 40px;
				background-color: #fff;
				border: 1px solid #cfcfcf;
				position: absolute;
				left: 200px;
				top: -60px;
				z-index: 10;

				a{
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #000;
					padding: 0 20px 0 10px;
					font-size: 14px;
					position: relative;

					&:before{
						content: "";
						display: block;
						width: 8px;
						height: 8px;
						border-bottom: 2px solid #ccc;
						border-left: 2px solid #ccc;
						transform: rotate(45deg);
						position: absolute;
						top: 15px;
						left: 15px;
					}
				}
			}

			#pageTop{
				width: 40px;
				height: 40px;
				background-color: $blue;
				position: absolute;
				right: 0;
				top: -60px;
				display: flex;
				justify-content: center;
				align-items: center;
				z-index: 10;
				cursor: pointer;
				padding-top: 3px;
				transition: all .3s;

				&:hover{
					opacity: .8;
				}

				&:after{
					content: "";
					display: block;
					width: 10px;
					height: 10px;
					border-left: 2px solid #fff;
					border-top: 2px solid #fff;
					transform: rotate(45deg);
				}
			}

			#footerLogo{

			}

			#footerMenu{
				display: flex;
				list-style: none;

				li{

					& + li{
						margin-left: 1.5em;
					}

					a{
						display: block;
						color: $green;
					}
				}
			}
		}

		p.copy{
			max-width: 1220px;
			width: 90%;
			margin: auto;
			font-size: 12px;
			line-height: 1;
		}
	}
}

#mainArea{

	#backBtn{
		width: 170px;
		height: 40px;
		background-color: #fff;
		border: 1px solid #cfcfcf;
		margin-top: 40px;

		a{
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #000;
			padding: 0 20px 0 10px;
			font-size: 14px;
			position: relative;

			&:before{
				content: "";
				display: block;
				width: 8px;
				height: 8px;
				border-bottom: 2px solid #ccc;
				border-left: 2px solid #ccc;
				transform: rotate(45deg);
				position: absolute;
				top: 15px;
				left: 15px;
			}
		}
	}
}




input::-ms-clear {
	visibility:hidden
}

.pd-0{
	padding:0px;
}

.d-none{
	display: none;
}

.d-inline-block{
	display: inline-block;
}

.pd-5{
	padding-top:5px;
}
.pd-10{
	padding-top:10px;
}



@media print{
	header, footer{
		display: none !important;
	}
}