/* --------------- variables --------------------- */

/* Break Point */
$breakpoint_sp		:599px;
$breakpoint_tb		:1000px;
$breakpoint_pc		:$breakpoint_sp + 1;


/* Color */
$c_base : #fff6e6;
$c_blue : #1560bb;
$c_blue-hov : #ecf1f7;
$c_pink : #f4866f;
$c_pink-hov : #fef3f0;

$mint: #bef1e6;
$blue: #6452aa;
$green: #088E98;

$ff_ja: 'Noto Sans JP', sans-serif;


// mixin --------------------------------

@mixin imgInTxt{
	vertical-align: middle;

	img{
		&[src $= "icon_word.gif"],
		&[src $= "icon_exl.gif"],
		&[src $= "icon_pdf.gif"],
		&[src $= "arrow_blue_1.gif"],
		&[src $= "icon_page.png"],
		&[src $= "arrow_square.gif"],
		&[src $= "icon_txt.gif"],
		&[src $= "arrow_blue_2.gif"],
		&[src $= "arrow2.gif"]{
			vertical-align: middle;
			margin-right: 5px;
			padding-bottom: 3px;
		}
		&[src $= "arrow_blue_1.gif"]{
			margin-left: -3px;
			margin-right: 8px;
		}
		
		&.icon{
			vertical-align: middle;
			padding-right: 5px;
		}
	}
}

@mixin tableTh{
	text-align: left;
	vertical-align: top;
	border: #ccc solid 1px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: #EBEBEB;
	background-repeat: repeat;
	font-weight: 400;
}

@mixin tableTd{
	text-align: left;
	vertical-align: top;
	border: 1px #ccc solid;
	padding: 5px 10px;
	background-repeat: repeat;
}


@mixin linkTxt{
	&:link{
		color: #1560BB;
		text-decoration: none;
	}

	&:visited{
		color: #9715d4;
		text-decoration: underline;
	}

	&:hover{
		color: #005aff;
		text-decoration: underline;
	}

	&:active{
		color: #005aff;
		text-decoration: underline;
	}

}

@mixin pluginArchive{
	ul{
		padding: 0;
		margin: 0;

		li{
			padding: 0;
			display: flex;
			align-items: flex-start;
			vertical-align: middle;

			@include imgInTxt;

			& + li{
				margin-top: 10px;
			}

			.date{
				width: 120px;
				font-size: 14px;
			}

			a{
				flex: 1;
			}

			&:before{
				display: none;
			}
		}
	}

}