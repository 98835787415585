@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");
/*base is →　! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

article, aside, footer, header, nav, section, figcaption, figure, main {
  display: block;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
  vertical-align: top;
}

table {
  border-collapse: collapse;
}

button, input, select, textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, html [type=button], [type=reset], [type=submit] {
  -webkit-appearance: button;
}

textarea {
  overflow: auto;
  resize: vertical;
}

[type=checkbox], [type=radio] {
  box-sizing: border-box;
  padding: 0;
}

a, area, button, input, label, select, summary, textarea, [tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

/* --------------- variables --------------------- */
/* Break Point */
/* Color */
@media screen and (max-width: 599px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    overflow-x: hidden;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: 400;
    background: #fff;
    line-height: 1.6;
    min-width: 320px;
    font-size: 16px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1;
  }
  dl {
    margin-bottom: 0;
  }
  .pbFooterArea,
  :root .pbAreaWrapper1:after {
    clear: none;
    overflow: visible;
  }
  .pbHeaderArea {
    overflow: visible;
  }
  a:link {
    color: #1560BB;
    text-decoration: none;
  }
  a:visited {
    color: #9715d4;
    text-decoration: underline;
  }
  a:hover {
    color: #005aff;
    text-decoration: underline;
  }
  a:active {
    color: #005aff;
    text-decoration: underline;
  }
  .align_center {
    text-align: center;
  }
  .bold {
    font-weight: bold;
  }
  .inner {
    margin: 0 15px;
  }
  .bt_small {
    display: inline-block;
    padding: 5px 35px 5px 30px;
    position: relative;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    width: 70%;
    text-align: center;
  }
  .bt_small::before {
    content: "";
    width: 6px;
    height: 6px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    left: 10px;
    margin-top: -3px;
  }
  .bg_pink {
    background: #f4866f;
  }
  .bg_blue {
    background: #1560bb;
  }
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    box-shadow: none;
  }
  input:focus {
    outline: 0;
    border-color: orange;
  }
  .forPC {
    display: none;
  }
  #page {
    overflow: hidden;
  }
  /* import */
  /* use Slick 
  @import "_slick";
  @import "_slick-theme";
  */
  /* ココから下には記入禁止 */
}
@media screen and (max-width: 599px) and (max-width: 979px) {
  header {
    position: relative;
    width: 100%;
  }
  header > .inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 auto;
    width: 95%;
    padding: 2.5vw 0;
  }
  header > .inner #site_logo {
    flex: 1;
    display: flex;
  }
  header > .inner #site_logo a {
    display: block;
  }
  header > .inner #site_logo a img {
    width: 100%;
  }
  header > .inner #site_logo #secTitle {
    font-size: 12px;
    border: solid 1px #cfcfcf;
    padding: 5px 7px;
    margin-left: 5px;
    color: #24504f;
    line-height: 1;
    white-space: nowrap;
  }
  header > .inner #headerRight {
    display: none;
  }
  header > .inner #menuBtn {
    background: url(../images/common/bt_menu.png) no-repeat;
    background-size: cover;
    width: 47px;
    height: 47px;
    color: #088E98;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 10px;
    padding-bottom: 5px;
    margin-left: 10px;
  }
  header > .inner #headerSearchWin {
    width: 100%;
    margin-top: 2.5vw;
  }
  header > .inner #headerSearchWin form #searchWinInner {
    display: flex;
    border: 1px solid #6452aa;
  }
  header > .inner #headerSearchWin form #searchWinInner input[type=text] {
    flex: 1;
    width: calc(100% - 50px);
    height: 48px;
    padding: 0 5px 0 10px;
  }
  header > .inner #headerSearchWin form #searchWinInner label {
    width: 48px;
    height: 48px;
    border-left: 1px solid #6452aa;
    background-image: url(../images/common/sp-search-btn.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 80%;
  }
  header > .inner #headerSearchWin form #searchWinInner label input[type=submit] {
    display: none;
  }
  header > .inner #headerSearchWin form #searchWinInner #searchCloseBtn {
    display: none;
  }
}
@media screen and (max-width: 599px) and (min-width: 980px) {
  header .inner {
    display: flex;
    justify-content: space-between;
    max-width: 1220px;
    width: 95%;
    position: relative;
    height: 140px;
  }
  header .inner #site_logo {
    display: flex;
    height: 140px;
    align-items: center;
  }
  header .inner #site_logo #secTitle {
    font-size: 18px;
    border: solid 1px #cfcfcf;
    padding: 10px 10px;
    margin-left: 10px;
    color: #088E98;
    line-height: 1;
  }
  header .inner #headerRight {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
  }
  header .inner #headerRight #user {
    text-align: right;
  }
  header .inner #headerRight #user #userName {
    max-width: 313px;
    margin-left: auto;
    background-color: #F7F6F4;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-size: 16px;
  }
  header .inner #headerRight #user #userName span {
    font-size: 16px;
    padding: 1em;
    font-weight: 700;
    color: #088E98;
  }
  header .inner #headerRight #user a#profile {
    font-size: 14px;
    color: #088E98;
    text-decoration: none;
  }
  header .inner #headerRight #user a#profile:hover {
    text-decoration: underline;
  }
  header .inner #headerRight #user a#Mailmagazine {
    font-size: 14px;
    color: #088E98;
    text-decoration: none;
  }
  header .inner #headerRight #user a#Mailmagazine:hover {
    text-decoration: underline;
  }
  header .inner #headerRight #user a#logOut {
    font-size: 14px;
    color: #088E98;
    text-decoration: none;
  }
  header .inner #headerRight #user a#logOut:hover {
    text-decoration: underline;
  }
  header .inner #headerRight #user span {
    font-size: 14px;
    color: #088E98;
  }
  header .inner #headerRight #searchBtn {
    margin-left: 30px;
    cursor: pointer;
  }
  header .inner #menuBtn {
    display: none;
  }
  header #headerSearchWin {
    position: absolute;
    height: 100%;
    width: 670px;
    top: 0;
    right: -100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    transition: all 0.3s;
  }
  header #headerSearchWin.isShow {
    right: 0;
  }
  header #headerSearchWin form {
    display: block;
    width: 100%;
  }
  header #headerSearchWin #searchWinInner {
    border: 2px solid #6452aa;
    display: flex;
    width: 100%;
    height: 80px;
  }
  header #headerSearchWin #searchWinInner input[type=text] {
    flex: 1;
    font-size: 16px;
    padding: 0 1em;
  }
  header #headerSearchWin #searchWinInner input[type=text]:focus {
    background-color: #f6f4ff;
  }
  header #headerSearchWin #searchWinInner label {
    width: 80px;
    background-image: url(../images/common/ico_search.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    cursor: pointer;
  }
  header #headerSearchWin #searchWinInner label:hover {
    background-color: rgba(100, 82, 170, 0.3);
  }
  header #headerSearchWin #searchWinInner label input {
    display: none;
  }
  header #headerSearchWin #searchWinInner #searchCloseBtn {
    width: 68px;
    border-left: 2px solid #6452aa;
    position: relative;
    text-align: center;
    color: #6452aa;
    padding-top: 5px;
    cursor: pointer;
  }
  header #headerSearchWin #searchWinInner #searchCloseBtn:hover {
    background-color: rgba(100, 82, 170, 0.3);
  }
  header #headerSearchWin #searchWinInner #searchCloseBtn:before {
    content: "";
    display: block;
    width: 2px;
    height: 30px;
    transform: rotate(45deg);
    background-color: #6452aa;
    position: absolute;
    bottom: 20px;
    left: 33px;
  }
  header #headerSearchWin #searchWinInner #searchCloseBtn:after {
    content: "";
    display: block;
    width: 2px;
    height: 30px;
    transform: rotate(-45deg);
    background-color: #6452aa;
    position: absolute;
    bottom: 20px;
    left: 33px;
  }
  header li.current {
    background: #cd634c;
  }
  header li.current:after {
    content: "";
    position: absolute;
    right: 0;
    /* bottom: -20px; */
    left: 0;
    width: 0px;
    height: 0px;
    margin: auto;
    border-style: solid;
    border-color: #cd634c transparent transparent transparent;
    border-width: 10px 10px 0 10px;
  }
}
@media screen and (max-width: 599px) and (max-width: 979px) {
  nav {
    background: #bef1e6;
    padding: 5px 0;
    position: fixed;
    top: -100vh;
    left: 0;
    width: 100%;
    z-index: 20;
    height: 100vh;
    overflow: hidden;
    transition: all 0.3s;
    z-index: 999;
  }
  nav.isOpen {
    top: 0;
  }
  nav #spNavHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    margin: 0 auto;
    height: 46px;
  }
  nav #spNavHeader #spUser {
    background-color: #fff;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-size: 16px;
    line-height: 1;
  }
  nav #spNavHeader #spUser span {
    font-size: 18px;
    padding: 0 0.5em;
    color: #088E98;
    max-width: 8em;
    overflow: hidden;
    line-height: 1em;
    height: 1em;
    display: inline-block;
  }
  nav #spNavHeader #closeBtn {
    width: 46px;
    height: 46px;
    margin-left: 10px;
  }
  nav #spNavHeader #closeBtn img {
    width: 100%;
    height: auto;
  }
  nav #gNavWrap {
    overflow: scroll;
    height: calc(100vh - 56px);
    padding-bottom: 40px;
  }
  nav ul.inner {
    margin: 0;
    margin-top: 20px;
    border-top: 2px solid #fff;
    list-style: none;
    padding: 0;
  }
  nav ul.inner > li {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    border-bottom: 2px solid #fff;
  }
  nav ul.inner > li .dropMenu {
    display: none;
  }
  nav ul.inner > li > a, nav ul.inner > li > span {
    color: #088E98;
    display: block;
    padding: 10px 15px;
    position: relative;
    color: #088E98;
  }
  nav ul.inner > li > a:after, nav ul.inner > li > span:after {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    border-top: 2px solid #088E98;
    border-right: 2px solid #088E98;
    transform: rotate(45deg) translateY(-50%);
    position: absolute;
    right: 15px;
    top: 50%;
  }
  nav ul.inner > li > span:after {
    display: none;
  }
  nav ul.inner > li .child {
    background-color: #EBFFFB;
    margin: 0 1em 1em;
  }
  nav ul.inner > li .child ul {
    list-style: none;
    margin-top: 0;
  }
  nav ul.inner > li .child ul li + li {
    border-top: 1px solid #bef1e6;
  }
  nav ul.inner > li .child ul li a {
    color: #088E98;
    text-decoration: none;
    position: relative;
    display: block;
    padding: 0.5em;
  }
  nav ul.inner > li .child ul li a:after {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    border-top: 2px solid #088E98;
    border-right: 2px solid #088E98;
    transform: rotate(45deg) translateY(-50%);
    position: absolute;
    right: 15px;
    top: 50%;
  }
}
@media screen and (max-width: 599px) and (min-width: 980px) {
  nav {
    background: #bef1e6;
    position: relative;
    z-index: 999;
  }
  nav #spNavHeader {
    display: none;
  }
  nav #gNavWrap {
    height: 68px;
  }
  nav #gNavWrap ul.inner {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    height: 68px;
    max-width: 1220px;
    width: 100%;
  }
  nav #gNavWrap ul.inner > li {
    font-size: 18px;
    position: relative;
    transition: all 0.3s;
  }
  nav #gNavWrap ul.inner > li.spMenu {
    display: none;
  }
  nav #gNavWrap ul.inner > li.now, nav #gNavWrap ul.inner > li:hover {
    background-color: #088E98;
  }
  nav #gNavWrap ul.inner > li.now:before, nav #gNavWrap ul.inner > li:hover:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 10px solid #088E98;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    left: calc(50% - 10px);
    bottom: -10px;
    z-index: 11;
  }
  nav #gNavWrap ul.inner > li.now a, nav #gNavWrap ul.inner > li.now span, nav #gNavWrap ul.inner > li:hover a, nav #gNavWrap ul.inner > li:hover span {
    color: #fff;
  }
  nav #gNavWrap ul.inner > li > a, nav #gNavWrap ul.inner > li span {
    color: #088E98;
    display: flex;
    height: 68px;
    align-items: center;
    position: relative;
    z-index: 1;
    text-decoration: none;
    padding: 0 35px;
    font-weight: 700;
  }
  nav #gNavWrap ul.inner > li.parent .child {
    border: 5px solid #bef1e6;
    border-width: 0 5px 5px 5px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateY(100%) translateX(-50%);
    display: none;
  }
  nav #gNavWrap ul.inner > li.parent .child ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
  }
  nav #gNavWrap ul.inner > li.parent .child ul li {
    background-color: #EBFFFB;
    position: relative;
  }
  nav #gNavWrap ul.inner > li.parent .child ul li + li:before {
    content: "";
    display: block;
    width: 1px;
    height: 1em;
    background-color: #088E98;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  nav #gNavWrap ul.inner > li.parent .child ul li a {
    display: block;
    line-height: 1;
    padding: 10px 15px;
    color: #088E98;
    text-decoration: none;
    white-space: nowrap;
    position: relative;
    border-bottom: 3px solid #EBFFFB;
  }
  nav #gNavWrap ul.inner > li.parent .child ul li a:hover {
    border-color: #088E98;
  }
}
@media screen and (max-width: 599px) and (max-width: 979px) {
  footer {
    margin-top: 35px;
    position: relative;
  }
  footer .inner {
    width: 100%;
    margin: 0;
    padding-top: 90px;
  }
  footer .inner #mente {
    width: 170px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    position: absolute;
    top: 0;
    left: 2.5vw;
  }
  footer .inner #mente a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    padding: 0 20px 0 10px;
  }
  footer .inner #backBtn {
    width: 170px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    position: absolute;
    left: 2.5vw;
    top: 45px;
    z-index: 10;
  }
  footer .inner #backBtn a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    padding: 0 20px 0 10px;
    font-size: 14px;
    position: relative;
  }
  footer .inner #backBtn a:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #ccc;
    border-left: 2px solid #ccc;
    transform: rotate(45deg);
    position: absolute;
    top: 15px;
    left: 15px;
  }
  footer .inner #pageTop {
    width: 40px;
    height: 40px;
    background-color: #088E98;
    position: absolute;
    right: 2.5vw;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    cursor: pointer;
    padding-top: 3px;
  }
  footer .inner #pageTop:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
    transform: rotate(45deg);
  }
  footer .inner #footerLogo {
    background-color: #bef1e6;
    padding: 15px 10px 10px;
    margin: 0 0 0;
    width: 100%;
    text-align: center;
  }
  footer .inner #footerLogo img {
    max-width: 60%;
    height: auto;
  }
  footer .inner #footerMenu {
    display: flex;
    margin: 0;
    justify-content: center;
    padding: 10px 5px;
    list-style: none;
    background-color: #bef1e6;
    flex-wrap: wrap;
  }
  footer .inner #footerMenu li {
    margin: 0 10px;
  }
  footer .inner #footerMenu li a {
    color: #088E98;
    font-size: 12px;
  }
  footer p {
    background-color: #bef1e6;
    font-size: 10px;
    padding: 10px;
    text-align: center;
  }
  footer p.copy {
    margin: 0;
  }
}
@media screen and (max-width: 599px) and (min-width: 980px) {
  footer {
    width: 100%;
    background-color: #bef1e6;
    padding: 0 0 50px;
  }
  footer .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    max-width: 1220px;
    width: 90%;
    margin: 0 auto;
    padding: 50px 0 0;
    z-index: 0;
  }
  footer .inner #mente {
    width: 170px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    position: absolute;
    left: 0;
    top: -60px;
    z-index: 10;
  }
  footer .inner #mente a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    padding: 0 20px 0 10px;
    font-size: 14px;
  }
  footer .inner #backBtn {
    width: 170px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    position: absolute;
    left: 200px;
    top: -60px;
    z-index: 10;
  }
  footer .inner #backBtn a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    padding: 0 20px 0 10px;
    font-size: 14px;
    position: relative;
  }
  footer .inner #backBtn a:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #ccc;
    border-left: 2px solid #ccc;
    transform: rotate(45deg);
    position: absolute;
    top: 15px;
    left: 15px;
  }
  footer .inner #pageTop {
    width: 40px;
    height: 40px;
    background-color: #6452aa;
    position: absolute;
    right: 0;
    top: -60px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    cursor: pointer;
    padding-top: 3px;
    transition: all 0.3s;
  }
  footer .inner #pageTop:hover {
    opacity: 0.8;
  }
  footer .inner #pageTop:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
    transform: rotate(45deg);
  }
  footer .inner #footerMenu {
    display: flex;
    list-style: none;
  }
  footer .inner #footerMenu li + li {
    margin-left: 1.5em;
  }
  footer .inner #footerMenu li a {
    display: block;
    color: #088E98;
  }
  footer p.copy {
    max-width: 1220px;
    width: 90%;
    margin: auto;
    font-size: 12px;
    line-height: 1;
  }
}
@media screen and (max-width: 599px) {
  #mainArea #backBtn {
    width: 170px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    margin-top: 40px;
  }
  #mainArea #backBtn a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    padding: 0 20px 0 10px;
    font-size: 14px;
    position: relative;
  }
  #mainArea #backBtn a:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #ccc;
    border-left: 2px solid #ccc;
    transform: rotate(45deg);
    position: absolute;
    top: 15px;
    left: 15px;
  }
  input::-ms-clear {
    visibility: hidden;
  }
  .pd-0 {
    padding: 0px;
  }
  .d-none {
    display: none;
  }
  .d-inline-block {
    display: inline-block;
  }
  .pd-5 {
    padding-top: 5px;
  }
  .pd-10 {
    padding-top: 10px;
  }
  .forPC {
    display: none !important;
  }
}
@media screen and (min-width: 1000px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    overflow-x: hidden;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: 400;
    background: #fff;
    line-height: 1.6;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1;
  }
  dl {
    margin-bottom: 0;
  }
  .pbFooterArea,
  :root .pbAreaWrapper1:after {
    clear: none;
    overflow: visible;
  }
  .pbHeaderArea {
    overflow: visible;
  }
  a:link {
    color: #1560BB;
    text-decoration: none;
  }
  a:visited {
    color: #9715d4;
    text-decoration: underline;
  }
  a:hover {
    color: #005aff;
    text-decoration: underline;
  }
  a:active {
    color: #005aff;
    text-decoration: underline;
  }
  .align_center {
    text-align: center;
  }
  .bold {
    font-weight: bold;
  }
  .bg_pink {
    background: #f4866f;
  }
  .bg_blue {
    background: #1560bb;
  }
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    box-shadow: none;
  }
  input:focus {
    outline: 0;
    border-color: orange;
  }
  #page {
    overflow: hidden;
  }
  /* import */
  /* use Slick 
  @import "_slick";
  @import "_slick-theme";
  */
  /* ココから下には記入禁止 */
}
@media screen and (min-width: 1000px) and (max-width: 979px) {
  header {
    position: relative;
    width: 100%;
  }
  header > .inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 auto;
    width: 95%;
    padding: 2.5vw 0;
  }
  header > .inner #site_logo {
    flex: 1;
    display: flex;
  }
  header > .inner #site_logo a {
    display: block;
  }
  header > .inner #site_logo a img {
    width: 100%;
  }
  header > .inner #site_logo #secTitle {
    font-size: 12px;
    border: solid 1px #cfcfcf;
    padding: 5px 7px;
    margin-left: 5px;
    color: #24504f;
    line-height: 1;
    white-space: nowrap;
  }
  header > .inner #headerRight {
    display: none;
  }
  header > .inner #menuBtn {
    background: url(../images/common/bt_menu.png) no-repeat;
    background-size: cover;
    width: 47px;
    height: 47px;
    color: #088E98;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 10px;
    padding-bottom: 5px;
    margin-left: 10px;
  }
  header > .inner #headerSearchWin {
    width: 100%;
    margin-top: 2.5vw;
  }
  header > .inner #headerSearchWin form #searchWinInner {
    display: flex;
    border: 1px solid #6452aa;
  }
  header > .inner #headerSearchWin form #searchWinInner input[type=text] {
    flex: 1;
    width: calc(100% - 50px);
    height: 48px;
    padding: 0 5px 0 10px;
  }
  header > .inner #headerSearchWin form #searchWinInner label {
    width: 48px;
    height: 48px;
    border-left: 1px solid #6452aa;
    background-image: url(../images/common/sp-search-btn.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 80%;
  }
  header > .inner #headerSearchWin form #searchWinInner label input[type=submit] {
    display: none;
  }
  header > .inner #headerSearchWin form #searchWinInner #searchCloseBtn {
    display: none;
  }
}
@media screen and (min-width: 1000px) and (min-width: 980px) {
  header .inner {
    display: flex;
    justify-content: space-between;
    max-width: 1220px;
    width: 95%;
    position: relative;
    height: 140px;
  }
  header .inner #site_logo {
    display: flex;
    height: 140px;
    align-items: center;
  }
  header .inner #site_logo #secTitle {
    font-size: 18px;
    border: solid 1px #cfcfcf;
    padding: 10px 10px;
    margin-left: 10px;
    color: #088E98;
    line-height: 1;
  }
  header .inner #headerRight {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
  }
  header .inner #headerRight #user {
    text-align: right;
  }
  header .inner #headerRight #user #userName {
    max-width: 313px;
    margin-left: auto;
    background-color: #F7F6F4;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-size: 16px;
  }
  header .inner #headerRight #user #userName span {
    font-size: 16px;
    padding: 1em;
    font-weight: 700;
    color: #088E98;
  }
  header .inner #headerRight #user a#profile {
    font-size: 14px;
    color: #088E98;
    text-decoration: none;
  }
  header .inner #headerRight #user a#profile:hover {
    text-decoration: underline;
  }
  header .inner #headerRight #user a#Mailmagazine {
    font-size: 14px;
    color: #088E98;
    text-decoration: none;
  }
  header .inner #headerRight #user a#Mailmagazine:hover {
    text-decoration: underline;
  }
  header .inner #headerRight #user a#logOut {
    font-size: 14px;
    color: #088E98;
    text-decoration: none;
  }
  header .inner #headerRight #user a#logOut:hover {
    text-decoration: underline;
  }
  header .inner #headerRight #user span {
    font-size: 14px;
    color: #088E98;
  }
  header .inner #headerRight #searchBtn {
    margin-left: 30px;
    cursor: pointer;
  }
  header .inner #menuBtn {
    display: none;
  }
  header #headerSearchWin {
    position: absolute;
    height: 100%;
    width: 670px;
    top: 0;
    right: -100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    transition: all 0.3s;
  }
  header #headerSearchWin.isShow {
    right: 0;
  }
  header #headerSearchWin form {
    display: block;
    width: 100%;
  }
  header #headerSearchWin #searchWinInner {
    border: 2px solid #6452aa;
    display: flex;
    width: 100%;
    height: 80px;
  }
  header #headerSearchWin #searchWinInner input[type=text] {
    flex: 1;
    font-size: 16px;
    padding: 0 1em;
  }
  header #headerSearchWin #searchWinInner input[type=text]:focus {
    background-color: #f6f4ff;
  }
  header #headerSearchWin #searchWinInner label {
    width: 80px;
    background-image: url(../images/common/ico_search.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    cursor: pointer;
  }
  header #headerSearchWin #searchWinInner label:hover {
    background-color: rgba(100, 82, 170, 0.3);
  }
  header #headerSearchWin #searchWinInner label input {
    display: none;
  }
  header #headerSearchWin #searchWinInner #searchCloseBtn {
    width: 68px;
    border-left: 2px solid #6452aa;
    position: relative;
    text-align: center;
    color: #6452aa;
    padding-top: 5px;
    cursor: pointer;
  }
  header #headerSearchWin #searchWinInner #searchCloseBtn:hover {
    background-color: rgba(100, 82, 170, 0.3);
  }
  header #headerSearchWin #searchWinInner #searchCloseBtn:before {
    content: "";
    display: block;
    width: 2px;
    height: 30px;
    transform: rotate(45deg);
    background-color: #6452aa;
    position: absolute;
    bottom: 20px;
    left: 33px;
  }
  header #headerSearchWin #searchWinInner #searchCloseBtn:after {
    content: "";
    display: block;
    width: 2px;
    height: 30px;
    transform: rotate(-45deg);
    background-color: #6452aa;
    position: absolute;
    bottom: 20px;
    left: 33px;
  }
  header li.current {
    background: #cd634c;
  }
  header li.current:after {
    content: "";
    position: absolute;
    right: 0;
    /* bottom: -20px; */
    left: 0;
    width: 0px;
    height: 0px;
    margin: auto;
    border-style: solid;
    border-color: #cd634c transparent transparent transparent;
    border-width: 10px 10px 0 10px;
  }
}
@media screen and (min-width: 1000px) and (max-width: 979px) {
  nav {
    background: #bef1e6;
    padding: 5px 0;
    position: fixed;
    top: -100vh;
    left: 0;
    width: 100%;
    z-index: 20;
    height: 100vh;
    overflow: hidden;
    transition: all 0.3s;
    z-index: 999;
  }
  nav.isOpen {
    top: 0;
  }
  nav #spNavHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    margin: 0 auto;
    height: 46px;
  }
  nav #spNavHeader #spUser {
    background-color: #fff;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-size: 16px;
    line-height: 1;
  }
  nav #spNavHeader #spUser span {
    font-size: 18px;
    padding: 0 0.5em;
    color: #088E98;
    max-width: 8em;
    overflow: hidden;
    line-height: 1em;
    height: 1em;
    display: inline-block;
  }
  nav #spNavHeader #closeBtn {
    width: 46px;
    height: 46px;
    margin-left: 10px;
  }
  nav #spNavHeader #closeBtn img {
    width: 100%;
    height: auto;
  }
  nav #gNavWrap {
    overflow: scroll;
    height: calc(100vh - 56px);
    padding-bottom: 40px;
  }
  nav ul.inner {
    margin: 0;
    margin-top: 20px;
    border-top: 2px solid #fff;
    list-style: none;
    padding: 0;
  }
  nav ul.inner > li {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    border-bottom: 2px solid #fff;
  }
  nav ul.inner > li .dropMenu {
    display: none;
  }
  nav ul.inner > li > a, nav ul.inner > li > span {
    color: #088E98;
    display: block;
    padding: 10px 15px;
    position: relative;
    color: #088E98;
  }
  nav ul.inner > li > a:after, nav ul.inner > li > span:after {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    border-top: 2px solid #088E98;
    border-right: 2px solid #088E98;
    transform: rotate(45deg) translateY(-50%);
    position: absolute;
    right: 15px;
    top: 50%;
  }
  nav ul.inner > li > span:after {
    display: none;
  }
  nav ul.inner > li .child {
    background-color: #EBFFFB;
    margin: 0 1em 1em;
  }
  nav ul.inner > li .child ul {
    list-style: none;
    margin-top: 0;
  }
  nav ul.inner > li .child ul li + li {
    border-top: 1px solid #bef1e6;
  }
  nav ul.inner > li .child ul li a {
    color: #088E98;
    text-decoration: none;
    position: relative;
    display: block;
    padding: 0.5em;
  }
  nav ul.inner > li .child ul li a:after {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    border-top: 2px solid #088E98;
    border-right: 2px solid #088E98;
    transform: rotate(45deg) translateY(-50%);
    position: absolute;
    right: 15px;
    top: 50%;
  }
}
@media screen and (min-width: 1000px) and (min-width: 980px) {
  nav {
    background: #bef1e6;
    position: relative;
    z-index: 999;
  }
  nav #spNavHeader {
    display: none;
  }
  nav #gNavWrap {
    height: 68px;
  }
  nav #gNavWrap ul.inner {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    height: 68px;
    max-width: 1220px;
    width: 100%;
  }
  nav #gNavWrap ul.inner > li {
    font-size: 18px;
    position: relative;
    transition: all 0.3s;
  }
  nav #gNavWrap ul.inner > li.spMenu {
    display: none;
  }
  nav #gNavWrap ul.inner > li.now, nav #gNavWrap ul.inner > li:hover {
    background-color: #088E98;
  }
  nav #gNavWrap ul.inner > li.now:before, nav #gNavWrap ul.inner > li:hover:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 10px solid #088E98;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    left: calc(50% - 10px);
    bottom: -10px;
    z-index: 11;
  }
  nav #gNavWrap ul.inner > li.now a, nav #gNavWrap ul.inner > li.now span, nav #gNavWrap ul.inner > li:hover a, nav #gNavWrap ul.inner > li:hover span {
    color: #fff;
  }
  nav #gNavWrap ul.inner > li > a, nav #gNavWrap ul.inner > li span {
    color: #088E98;
    display: flex;
    height: 68px;
    align-items: center;
    position: relative;
    z-index: 1;
    text-decoration: none;
    padding: 0 35px;
    font-weight: 700;
  }
  nav #gNavWrap ul.inner > li.parent .child {
    border: 5px solid #bef1e6;
    border-width: 0 5px 5px 5px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateY(100%) translateX(-50%);
    display: none;
  }
  nav #gNavWrap ul.inner > li.parent .child ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
  }
  nav #gNavWrap ul.inner > li.parent .child ul li {
    background-color: #EBFFFB;
    position: relative;
  }
  nav #gNavWrap ul.inner > li.parent .child ul li + li:before {
    content: "";
    display: block;
    width: 1px;
    height: 1em;
    background-color: #088E98;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  nav #gNavWrap ul.inner > li.parent .child ul li a {
    display: block;
    line-height: 1;
    padding: 10px 15px;
    color: #088E98;
    text-decoration: none;
    white-space: nowrap;
    position: relative;
    border-bottom: 3px solid #EBFFFB;
  }
  nav #gNavWrap ul.inner > li.parent .child ul li a:hover {
    border-color: #088E98;
  }
}
@media screen and (min-width: 1000px) and (max-width: 979px) {
  footer {
    margin-top: 35px;
    position: relative;
  }
  footer .inner {
    width: 100%;
    margin: 0;
    padding-top: 90px;
  }
  footer .inner #mente {
    width: 170px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    position: absolute;
    top: 0;
    left: 2.5vw;
  }
  footer .inner #mente a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    padding: 0 20px 0 10px;
  }
  footer .inner #backBtn {
    width: 170px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    position: absolute;
    left: 2.5vw;
    top: 45px;
    z-index: 10;
  }
  footer .inner #backBtn a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    padding: 0 20px 0 10px;
    font-size: 14px;
    position: relative;
  }
  footer .inner #backBtn a:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #ccc;
    border-left: 2px solid #ccc;
    transform: rotate(45deg);
    position: absolute;
    top: 15px;
    left: 15px;
  }
  footer .inner #pageTop {
    width: 40px;
    height: 40px;
    background-color: #088E98;
    position: absolute;
    right: 2.5vw;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    cursor: pointer;
    padding-top: 3px;
  }
  footer .inner #pageTop:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
    transform: rotate(45deg);
  }
  footer .inner #footerLogo {
    background-color: #bef1e6;
    padding: 15px 10px 10px;
    margin: 0 0 0;
    width: 100%;
    text-align: center;
  }
  footer .inner #footerLogo img {
    max-width: 60%;
    height: auto;
  }
  footer .inner #footerMenu {
    display: flex;
    margin: 0;
    justify-content: center;
    padding: 10px 5px;
    list-style: none;
    background-color: #bef1e6;
    flex-wrap: wrap;
  }
  footer .inner #footerMenu li {
    margin: 0 10px;
  }
  footer .inner #footerMenu li a {
    color: #088E98;
    font-size: 12px;
  }
  footer p {
    background-color: #bef1e6;
    font-size: 10px;
    padding: 10px;
    text-align: center;
  }
  footer p.copy {
    margin: 0;
  }
}
@media screen and (min-width: 1000px) and (min-width: 980px) {
  footer {
    width: 100%;
    background-color: #bef1e6;
    padding: 0 0 50px;
  }
  footer .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    max-width: 1220px;
    width: 90%;
    margin: 0 auto;
    padding: 50px 0 0;
    z-index: 0;
  }
  footer .inner #mente {
    width: 170px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    position: absolute;
    left: 0;
    top: -60px;
    z-index: 10;
  }
  footer .inner #mente a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    padding: 0 20px 0 10px;
    font-size: 14px;
  }
  footer .inner #backBtn {
    width: 170px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    position: absolute;
    left: 200px;
    top: -60px;
    z-index: 10;
  }
  footer .inner #backBtn a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    padding: 0 20px 0 10px;
    font-size: 14px;
    position: relative;
  }
  footer .inner #backBtn a:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #ccc;
    border-left: 2px solid #ccc;
    transform: rotate(45deg);
    position: absolute;
    top: 15px;
    left: 15px;
  }
  footer .inner #pageTop {
    width: 40px;
    height: 40px;
    background-color: #6452aa;
    position: absolute;
    right: 0;
    top: -60px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    cursor: pointer;
    padding-top: 3px;
    transition: all 0.3s;
  }
  footer .inner #pageTop:hover {
    opacity: 0.8;
  }
  footer .inner #pageTop:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
    transform: rotate(45deg);
  }
  footer .inner #footerMenu {
    display: flex;
    list-style: none;
  }
  footer .inner #footerMenu li + li {
    margin-left: 1.5em;
  }
  footer .inner #footerMenu li a {
    display: block;
    color: #088E98;
  }
  footer p.copy {
    max-width: 1220px;
    width: 90%;
    margin: auto;
    font-size: 12px;
    line-height: 1;
  }
}
@media screen and (min-width: 1000px) {
  #mainArea #backBtn {
    width: 170px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    margin-top: 40px;
  }
  #mainArea #backBtn a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    padding: 0 20px 0 10px;
    font-size: 14px;
    position: relative;
  }
  #mainArea #backBtn a:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #ccc;
    border-left: 2px solid #ccc;
    transform: rotate(45deg);
    position: absolute;
    top: 15px;
    left: 15px;
  }
  input::-ms-clear {
    visibility: hidden;
  }
  .pd-0 {
    padding: 0px;
  }
  .d-none {
    display: none;
  }
  .d-inline-block {
    display: inline-block;
  }
  .pd-5 {
    padding-top: 5px;
  }
  .pd-10 {
    padding-top: 10px;
  }
}
@media print, screen and (min-width: 600px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    overflow-x: hidden;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: 400;
    background: #fff;
    line-height: 1.6;
    font-size: 16px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1;
  }
  dl {
    margin-bottom: 0;
  }
  .pbFooterArea,
  :root .pbAreaWrapper1:after {
    clear: none;
    overflow: visible;
  }
  .pbHeaderArea {
    overflow: visible;
  }
  a:link {
    color: #1560BB;
    text-decoration: none;
  }
  a:visited {
    color: #9715d4;
    text-decoration: underline;
  }
  a:hover {
    color: #005aff;
    text-decoration: underline;
  }
  a:active {
    color: #005aff;
    text-decoration: underline;
  }
  img {
    max-width: 100%;
  }
  .align_center {
    text-align: center;
  }
  .bold {
    font-weight: bold;
  }
  .inner {
    max-width: 1220px;
    width: 95%;
    margin: 0 auto;
    margin: 0 auto;
    position: relative;
  }
  .text_link {
    padding-left: 15px;
    display: inline-block;
    position: relative;
  }
  .text_link:hover {
    color: #f4866f;
  }
  .text_link::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: rotate(45deg);
    width: 6px;
    height: 6px;
    border-top: 2px solid #f4866f;
    border-right: 2px solid #f4866f;
    margin-top: -3px;
  }
  .bt_small {
    display: inline-block;
    padding: 5px 35px 5px 30px;
    position: relative;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    transition: all 0.4s;
  }
  .bt_small:hover {
    opacity: 0.8;
  }
  .bt_small::before {
    content: "";
    width: 6px;
    height: 6px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    left: 10px;
    margin-top: -3px;
  }
  .bg_pink {
    background: #f4866f;
  }
  .bg_blue {
    background: #1560bb;
  }
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    box-shadow: none;
  }
  input:focus {
    outline: 0;
    border-color: orange;
  }
  .forSP {
    display: none;
  }
  #page {
    overflow: hidden;
  }
  /* import */
  /* use Slick 
  @import "_slick";
  @import "_slick-theme";
  */
  /* ココから下には記入禁止 */
}
@media screen and (min-width: 600px) and (max-width: 979px) {
  header {
    position: relative;
    width: 100%;
  }
  header > .inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 auto;
    width: 95%;
    padding: 2.5vw 0;
  }
  header > .inner #site_logo {
    flex: 1;
    display: flex;
  }
  header > .inner #site_logo a {
    display: block;
  }
  header > .inner #site_logo a img {
    width: 100%;
  }
  header > .inner #site_logo #secTitle {
    font-size: 12px;
    border: solid 1px #cfcfcf;
    padding: 5px 7px;
    margin-left: 5px;
    color: #24504f;
    line-height: 1;
    white-space: nowrap;
  }
  header > .inner #headerRight {
    display: none;
  }
  header > .inner #menuBtn {
    background: url(../images/common/bt_menu.png) no-repeat;
    background-size: cover;
    width: 47px;
    height: 47px;
    color: #088E98;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 10px;
    padding-bottom: 5px;
    margin-left: 10px;
  }
  header > .inner #headerSearchWin {
    width: 100%;
    margin-top: 2.5vw;
  }
  header > .inner #headerSearchWin form #searchWinInner {
    display: flex;
    border: 1px solid #6452aa;
  }
  header > .inner #headerSearchWin form #searchWinInner input[type=text] {
    flex: 1;
    width: calc(100% - 50px);
    height: 48px;
    padding: 0 5px 0 10px;
  }
  header > .inner #headerSearchWin form #searchWinInner label {
    width: 48px;
    height: 48px;
    border-left: 1px solid #6452aa;
    background-image: url(../images/common/sp-search-btn.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 80%;
  }
  header > .inner #headerSearchWin form #searchWinInner label input[type=submit] {
    display: none;
  }
  header > .inner #headerSearchWin form #searchWinInner #searchCloseBtn {
    display: none;
  }
}
@media print, screen and (min-width: 600px) and (min-width: 980px) {
  header .inner {
    display: flex;
    justify-content: space-between;
    max-width: 1220px;
    width: 95%;
    position: relative;
    height: 140px;
  }
  header .inner #site_logo {
    display: flex;
    height: 140px;
    align-items: center;
  }
  header .inner #site_logo #secTitle {
    font-size: 18px;
    border: solid 1px #cfcfcf;
    padding: 10px 10px;
    margin-left: 10px;
    color: #088E98;
    line-height: 1;
  }
  header .inner #headerRight {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
  }
  header .inner #headerRight #user {
    text-align: right;
  }
  header .inner #headerRight #user #userName {
    max-width: 313px;
    margin-left: auto;
    background-color: #F7F6F4;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-size: 16px;
  }
  header .inner #headerRight #user #userName span {
    font-size: 16px;
    padding: 1em;
    font-weight: 700;
    color: #088E98;
  }
  header .inner #headerRight #user a#profile {
    font-size: 14px;
    color: #088E98;
    text-decoration: none;
  }
  header .inner #headerRight #user a#profile:hover {
    text-decoration: underline;
  }
  header .inner #headerRight #user a#Mailmagazine {
    font-size: 14px;
    color: #088E98;
    text-decoration: none;
  }
  header .inner #headerRight #user a#Mailmagazine:hover {
    text-decoration: underline;
  }
  header .inner #headerRight #user a#logOut {
    font-size: 14px;
    color: #088E98;
    text-decoration: none;
  }
  header .inner #headerRight #user a#logOut:hover {
    text-decoration: underline;
  }
  header .inner #headerRight #user span {
    font-size: 14px;
    color: #088E98;
  }
  header .inner #headerRight #searchBtn {
    margin-left: 30px;
    cursor: pointer;
  }
  header .inner #menuBtn {
    display: none;
  }
  header #headerSearchWin {
    position: absolute;
    height: 100%;
    width: 670px;
    top: 0;
    right: -100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    transition: all 0.3s;
  }
  header #headerSearchWin.isShow {
    right: 0;
  }
  header #headerSearchWin form {
    display: block;
    width: 100%;
  }
  header #headerSearchWin #searchWinInner {
    border: 2px solid #6452aa;
    display: flex;
    width: 100%;
    height: 80px;
  }
  header #headerSearchWin #searchWinInner input[type=text] {
    flex: 1;
    font-size: 16px;
    padding: 0 1em;
  }
  header #headerSearchWin #searchWinInner input[type=text]:focus {
    background-color: #f6f4ff;
  }
  header #headerSearchWin #searchWinInner label {
    width: 80px;
    background-image: url(../images/common/ico_search.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    cursor: pointer;
  }
  header #headerSearchWin #searchWinInner label:hover {
    background-color: rgba(100, 82, 170, 0.3);
  }
  header #headerSearchWin #searchWinInner label input {
    display: none;
  }
  header #headerSearchWin #searchWinInner #searchCloseBtn {
    width: 68px;
    border-left: 2px solid #6452aa;
    position: relative;
    text-align: center;
    color: #6452aa;
    padding-top: 5px;
    cursor: pointer;
  }
  header #headerSearchWin #searchWinInner #searchCloseBtn:hover {
    background-color: rgba(100, 82, 170, 0.3);
  }
  header #headerSearchWin #searchWinInner #searchCloseBtn:before {
    content: "";
    display: block;
    width: 2px;
    height: 30px;
    transform: rotate(45deg);
    background-color: #6452aa;
    position: absolute;
    bottom: 20px;
    left: 33px;
  }
  header #headerSearchWin #searchWinInner #searchCloseBtn:after {
    content: "";
    display: block;
    width: 2px;
    height: 30px;
    transform: rotate(-45deg);
    background-color: #6452aa;
    position: absolute;
    bottom: 20px;
    left: 33px;
  }
  header li.current {
    background: #cd634c;
  }
  header li.current:after {
    content: "";
    position: absolute;
    right: 0;
    /* bottom: -20px; */
    left: 0;
    width: 0px;
    height: 0px;
    margin: auto;
    border-style: solid;
    border-color: #cd634c transparent transparent transparent;
    border-width: 10px 10px 0 10px;
  }
}
@media screen and (min-width: 600px) and (max-width: 979px) {
  nav {
    background: #bef1e6;
    padding: 5px 0;
    position: fixed;
    top: -100vh;
    left: 0;
    width: 100%;
    z-index: 20;
    height: 100vh;
    overflow: hidden;
    transition: all 0.3s;
    z-index: 999;
  }
  nav.isOpen {
    top: 0;
  }
  nav #spNavHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    margin: 0 auto;
    height: 46px;
  }
  nav #spNavHeader #spUser {
    background-color: #fff;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-size: 16px;
    line-height: 1;
  }
  nav #spNavHeader #spUser span {
    font-size: 18px;
    padding: 0 0.5em;
    color: #088E98;
    max-width: 8em;
    overflow: hidden;
    line-height: 1em;
    height: 1em;
    display: inline-block;
  }
  nav #spNavHeader #closeBtn {
    width: 46px;
    height: 46px;
    margin-left: 10px;
  }
  nav #spNavHeader #closeBtn img {
    width: 100%;
    height: auto;
  }
  nav #gNavWrap {
    overflow: scroll;
    height: calc(100vh - 56px);
    padding-bottom: 40px;
  }
  nav ul.inner {
    margin: 0;
    margin-top: 20px;
    border-top: 2px solid #fff;
    list-style: none;
    padding: 0;
  }
  nav ul.inner > li {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    border-bottom: 2px solid #fff;
  }
  nav ul.inner > li .dropMenu {
    display: none;
  }
  nav ul.inner > li > a, nav ul.inner > li > span {
    color: #088E98;
    display: block;
    padding: 10px 15px;
    position: relative;
    color: #088E98;
  }
  nav ul.inner > li > a:after, nav ul.inner > li > span:after {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    border-top: 2px solid #088E98;
    border-right: 2px solid #088E98;
    transform: rotate(45deg) translateY(-50%);
    position: absolute;
    right: 15px;
    top: 50%;
  }
  nav ul.inner > li > span:after {
    display: none;
  }
  nav ul.inner > li .child {
    background-color: #EBFFFB;
    margin: 0 1em 1em;
  }
  nav ul.inner > li .child ul {
    list-style: none;
    margin-top: 0;
  }
  nav ul.inner > li .child ul li + li {
    border-top: 1px solid #bef1e6;
  }
  nav ul.inner > li .child ul li a {
    color: #088E98;
    text-decoration: none;
    position: relative;
    display: block;
    padding: 0.5em;
  }
  nav ul.inner > li .child ul li a:after {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    border-top: 2px solid #088E98;
    border-right: 2px solid #088E98;
    transform: rotate(45deg) translateY(-50%);
    position: absolute;
    right: 15px;
    top: 50%;
  }
}
@media print, screen and (min-width: 600px) and (min-width: 980px) {
  nav {
    background: #bef1e6;
    position: relative;
    z-index: 999;
  }
  nav #spNavHeader {
    display: none;
  }
  nav #gNavWrap {
    height: 68px;
  }
  nav #gNavWrap ul.inner {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    height: 68px;
    max-width: 1220px;
    width: 100%;
  }
  nav #gNavWrap ul.inner > li {
    font-size: 18px;
    position: relative;
    transition: all 0.3s;
  }
  nav #gNavWrap ul.inner > li.spMenu {
    display: none;
  }
  nav #gNavWrap ul.inner > li.now, nav #gNavWrap ul.inner > li:hover {
    background-color: #088E98;
  }
  nav #gNavWrap ul.inner > li.now:before, nav #gNavWrap ul.inner > li:hover:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 10px solid #088E98;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    left: calc(50% - 10px);
    bottom: -10px;
    z-index: 11;
  }
  nav #gNavWrap ul.inner > li.now a, nav #gNavWrap ul.inner > li.now span, nav #gNavWrap ul.inner > li:hover a, nav #gNavWrap ul.inner > li:hover span {
    color: #fff;
  }
  nav #gNavWrap ul.inner > li > a, nav #gNavWrap ul.inner > li span {
    color: #088E98;
    display: flex;
    height: 68px;
    align-items: center;
    position: relative;
    z-index: 1;
    text-decoration: none;
    padding: 0 35px;
    font-weight: 700;
  }
  nav #gNavWrap ul.inner > li.parent .child {
    border: 5px solid #bef1e6;
    border-width: 0 5px 5px 5px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateY(100%) translateX(-50%);
    display: none;
  }
  nav #gNavWrap ul.inner > li.parent .child ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
  }
  nav #gNavWrap ul.inner > li.parent .child ul li {
    background-color: #EBFFFB;
    position: relative;
  }
  nav #gNavWrap ul.inner > li.parent .child ul li + li:before {
    content: "";
    display: block;
    width: 1px;
    height: 1em;
    background-color: #088E98;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  nav #gNavWrap ul.inner > li.parent .child ul li a {
    display: block;
    line-height: 1;
    padding: 10px 15px;
    color: #088E98;
    text-decoration: none;
    white-space: nowrap;
    position: relative;
    border-bottom: 3px solid #EBFFFB;
  }
  nav #gNavWrap ul.inner > li.parent .child ul li a:hover {
    border-color: #088E98;
  }
}
@media screen and (min-width: 600px) and (max-width: 979px) {
  footer {
    margin-top: 35px;
    position: relative;
  }
  footer .inner {
    width: 100%;
    margin: 0;
    padding-top: 90px;
  }
  footer .inner #mente {
    width: 170px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    position: absolute;
    top: 0;
    left: 2.5vw;
  }
  footer .inner #mente a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    padding: 0 20px 0 10px;
  }
  footer .inner #backBtn {
    width: 170px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    position: absolute;
    left: 2.5vw;
    top: 45px;
    z-index: 10;
  }
  footer .inner #backBtn a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    padding: 0 20px 0 10px;
    font-size: 14px;
    position: relative;
  }
  footer .inner #backBtn a:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #ccc;
    border-left: 2px solid #ccc;
    transform: rotate(45deg);
    position: absolute;
    top: 15px;
    left: 15px;
  }
  footer .inner #pageTop {
    width: 40px;
    height: 40px;
    background-color: #088E98;
    position: absolute;
    right: 2.5vw;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    cursor: pointer;
    padding-top: 3px;
  }
  footer .inner #pageTop:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
    transform: rotate(45deg);
  }
  footer .inner #footerLogo {
    background-color: #bef1e6;
    padding: 15px 10px 10px;
    margin: 0 0 0;
    width: 100%;
    text-align: center;
  }
  footer .inner #footerLogo img {
    max-width: 60%;
    height: auto;
  }
  footer .inner #footerMenu {
    display: flex;
    margin: 0;
    justify-content: center;
    padding: 10px 5px;
    list-style: none;
    background-color: #bef1e6;
    flex-wrap: wrap;
  }
  footer .inner #footerMenu li {
    margin: 0 10px;
  }
  footer .inner #footerMenu li a {
    color: #088E98;
    font-size: 12px;
  }
  footer p {
    background-color: #bef1e6;
    font-size: 10px;
    padding: 10px;
    text-align: center;
  }
  footer p.copy {
    margin: 0;
  }
}
@media print, screen and (min-width: 600px) and (min-width: 980px) {
  footer {
    width: 100%;
    background-color: #bef1e6;
    padding: 0 0 50px;
  }
  footer .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    max-width: 1220px;
    width: 90%;
    margin: 0 auto;
    padding: 50px 0 0;
    z-index: 0;
  }
  footer .inner #mente {
    width: 170px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    position: absolute;
    left: 0;
    top: -60px;
    z-index: 10;
  }
  footer .inner #mente a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    padding: 0 20px 0 10px;
    font-size: 14px;
  }
  footer .inner #backBtn {
    width: 170px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    position: absolute;
    left: 200px;
    top: -60px;
    z-index: 10;
  }
  footer .inner #backBtn a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    padding: 0 20px 0 10px;
    font-size: 14px;
    position: relative;
  }
  footer .inner #backBtn a:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #ccc;
    border-left: 2px solid #ccc;
    transform: rotate(45deg);
    position: absolute;
    top: 15px;
    left: 15px;
  }
  footer .inner #pageTop {
    width: 40px;
    height: 40px;
    background-color: #6452aa;
    position: absolute;
    right: 0;
    top: -60px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    cursor: pointer;
    padding-top: 3px;
    transition: all 0.3s;
  }
  footer .inner #pageTop:hover {
    opacity: 0.8;
  }
  footer .inner #pageTop:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
    transform: rotate(45deg);
  }
  footer .inner #footerMenu {
    display: flex;
    list-style: none;
  }
  footer .inner #footerMenu li + li {
    margin-left: 1.5em;
  }
  footer .inner #footerMenu li a {
    display: block;
    color: #088E98;
  }
  footer p.copy {
    max-width: 1220px;
    width: 90%;
    margin: auto;
    font-size: 12px;
    line-height: 1;
  }
}
@media print, screen and (min-width: 600px) {
  #mainArea #backBtn {
    width: 170px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    margin-top: 40px;
  }
  #mainArea #backBtn a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    padding: 0 20px 0 10px;
    font-size: 14px;
    position: relative;
  }
  #mainArea #backBtn a:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #ccc;
    border-left: 2px solid #ccc;
    transform: rotate(45deg);
    position: absolute;
    top: 15px;
    left: 15px;
  }
  input::-ms-clear {
    visibility: hidden;
  }
  .pd-0 {
    padding: 0px;
  }
  .d-none {
    display: none;
  }
  .d-inline-block {
    display: inline-block;
  }
  .pd-5 {
    padding-top: 5px;
  }
  .pd-10 {
    padding-top: 10px;
  }
}
@media print {
  header, footer {
    display: none !important;
  }
}
@media print, screen and (min-width: 600px) {
  .forSP {
    display: none !important;
  }
}