.sample{
	@if $type == sp{
	}
	@if $type == pc{
	}
}


/* import */

/* use Slick 
@import "_slick";
@import "_slick-theme";
*/

/* ココから下には記入禁止 */

.forPC{	@if $type == sp{display: none!important;}}
.forSP{	@if $type == pc{display: none!important;}}
