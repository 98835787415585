@charset "UTF-8";

// IMPPRT INIT

@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');

@import "lib/_sanitize";    // Reset CSS
@import "_setting";

// FOR SMARTPHONE DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (max-width:$breakpoint_sp) {
	$type: sp;
	@import "_common";
	@import "_layout";
}

// FOR TABLET DISPLAY SIZE
//-----------------------------------------------------------------
@media  screen and (min-width:$breakpoint_tb) {
	$type: tb;
	@import "_common";
	@import "_layout";
}

// FOR LARGE DISPLAY SIZE
//-----------------------------------------------------------------
@media print, screen and (min-width:$breakpoint_pc) {
	$type: pc;
	@import "_common";
	@import "_layout";
}

// STYLE FOR PRINT
//-----------------------------------------------------------------
@media print {
	$type: print;
}
